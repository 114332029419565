/**
 * @author sebastian.qvarfordt@svenskaspel.se (Sebastian Qvarfordt)
 */

import PropTypes from 'prop-types';
import './../../stylesheets/list-group.less';

const ListGroup = ({ children, className, inverted, menu }) => {
  const getStyleListGroupInverted = (inverted) => (inverted ? ' list-group-inverted' : ' list-group-default');
  const getStyleListGroupMenu = (menu) => (menu ? ' list-group-menu' : '');
  const getClassName = (className) => (className ? ` ${className}` : '');
  return (
    <ul className={`list-group${getStyleListGroupInverted(inverted)}${getStyleListGroupMenu(menu)}${getClassName(className)}`}>
      {children}
    </ul>
  );
};

ListGroup.propTypes = {
  /**
   * Content
   */
  children: PropTypes.node,
  /**
   * Add custom classes
   */
  className: PropTypes.string,
  /**
   * Inverted background and text
   */
  inverted: PropTypes.bool,
  menu: PropTypes.bool
};

ListGroup.defaultProps = {
};

export default ListGroup;
